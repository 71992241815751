import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "white",
  borderRadius: 12,
  padding: "20px",
};

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const Tab = styled(TabUnstyled)`
  color: black;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  border: none;
  display: flex;
  justify-content: center;
  border-top: none;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;

  &:hover {
    background-color: ${blue[300]};
  }

  &:focus {
    color: #fff;
    // outline: 2px solid ${blue[200]};
    // outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    border: none;
    border-top: 4px solid black;
    color: black;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  border-top-left-radius: 10;
  border-top-right-radius: 10;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  // background-color: ${blue[500]};
  border: none;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

const CustomInput = styled(Input)`
  border: 1px solid black;
  padding: 5px 10px;
  width: 100%;

  &::after {
    display: none;
  }
  &::before {
    display: none;
  }
`;

function PopUpModal({
  open,
  onClose,
  symbol,
  buy,
  value,
  onChageValue,
  totalValue,
}) {
  const [selectedOption, setSelectedOption] = useState("");
  return (
    <Box>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={style}
          justifyContent={"center"}
          display={"flex"}
          flexDirection={"column"}
          padding={'10px'}
        >
          {/* <Typography>Buy Modal</Typography> */}
          <TabsUnstyled defaultValue={buy === true ? 0 : 1}>
            <TabsList>
              <Tab
                style={{
                  borderTopLeftRadius: 6,
                  borderColor: "green",
                  // borderTop: "5px solid green",
                }}
              >
                Buy
              </Tab>
              <Tab style={{ borderTopRightRadius: 6, borderTopColor: "red" }}>
                Sell
              </Tab>
            </TabsList>
            <TabPanel value={0} style={{ width: "100%" }}>
              {/* <Typography>Buy Crypto</Typography> */}
              <Box style={{ marginTop: 10 }}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: 20,
                  }}
                >
                  <TextField
                    id="filled-select-currency-native"
                    select
                    label="Native select"
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    SelectProps={{
                      native: true,
                    }}
                    variant="standard"
                  >
                    <option value={"limit"}>Limit</option>
                    <option value={"stop"}>Stop Limit</option>
                  </TextField>
                </Box>
                <Box
                  style={{
                    marginTop: 20,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid black",
                    borderRadius: 10,
                  }}
                >
                  <Typography
                    fontSize={10}
                    fontWeight={"bold"}
                    textAlign={"right"}
                    style={{
                      borderRight: "1px solid black",
                      paddingRight: 10,
                      marginLeft: 10,
                    }}
                  >{`At Price\n INR`}</Typography>
                  <CustomInput
                    style={{ border: "none" }}
                    value={value}
                    type="number"
                    disabled
                  />
                </Box>
                <Box
                  style={{
                    marginTop: 20,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid black",
                    borderRadius: 10,
                  }}
                >
                  <Typography
                    fontSize={10}
                    fontWeight={"bold"}
                    textAlign={"right"}
                    style={{
                      borderRight: "1px solid black",
                      paddingRight: 10,
                      marginLeft: 10,
                    }}
                  >{`Amount\n${symbol.toUpperCase()}`}</Typography>
                  <CustomInput
                    style={{ border: "none" }}
                    // value={value}
                    type="number"
                    onChange={onChageValue}
                  />
                </Box>
                <Box
                  style={{
                    marginTop: 20,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid black",
                    flexDirection: "column",
                    borderRadius: 10,
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <Typography
                      fontSize={10}
                      fontWeight={"bold"}
                      textAlign={"right"}
                      style={{
                        borderRight: "1px solid black",
                        paddingRight: 10,
                        marginLeft: 10,
                      }}
                    >{`Amount\n${symbol.toUpperCase()}`}</Typography>
                    <CustomInput
                      style={{ border: "none" }}
                      value={totalValue}
                      type="number"
                      disabled
                      onChange={onChageValue}
                    />
                  </Box>
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      padding: "5px 0",
                    }}
                  >
                    <Typography
                      style={{
                        borderRight: "1px solid black",
                        width: "45%",
                        paddingLeft: 10,
                      }}
                    >
                      Rs 0
                    </Typography>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "55%",
                        padding: "0 10px",
                      }}
                    >
                      <div>25%</div>
                      <div>50%</div>
                      <div>75%</div>
                      <div>100%</div>
                    </Box>
                  </Box>
                </Box>
                <Box py={"10px"}>
                  <Button
                    variant="contained"
                    style={{ width: "100%" }}
                  >{`Buy ${symbol.toUpperCase()}`}</Button>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value={1} style={{ width: "100%" }}>
              {/* <Typography>Buy Crypto</Typography> */}
              <Box style={{ marginTop: 10 }}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: 20,
                  }}
                >
                  <TextField
                    id="filled-select-currency-native"
                    select
                    label="Native select"
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    SelectProps={{
                      native: true,
                    }}
                    variant="standard"
                  >
                    <option value={"limit"}>Limit</option>
                    <option value={"stop"}>Stop Limit</option>
                  </TextField>
                </Box>
                <Box
                  style={{
                    marginTop: 20,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid black",
                    borderRadius: 10,
                  }}
                >
                  <Typography
                    fontSize={10}
                    fontWeight={"bold"}
                    textAlign={"right"}
                    style={{
                      borderRight: "1px solid black",
                      paddingRight: 10,
                      marginLeft: 10,
                      width: 80,
                    }}
                  >{`STOP PRICE\n INR`}</Typography>
                  <CustomInput
                    style={{ border: "none" }}
                    value={value}
                    type="number"
                    disabled
                  />
                </Box>
                <Box
                  style={{
                    marginTop: 20,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid black",
                    borderRadius: 10,
                  }}
                >
                  <Typography
                    fontSize={10}
                    fontWeight={"bold"}
                    textAlign={"right"}
                    style={{
                      borderRight: "1px solid black",
                      paddingRight: 10,
                      marginLeft: 10,
                      width: 80,
                    }}
                  >{`Amount\n${symbol.toUpperCase()}`}</Typography>
                  <CustomInput
                    style={{ border: "none" }}
                    // value={value}
                    endAdornment={
                      <InputAdornment position="end">
                        <Button style={{ color: "red" }}>Highest Price</Button>
                      </InputAdornment>
                    }
                    type="number"
                    onChange={onChageValue}
                  />
                </Box>
                {selectedOption === "stop" &&
                  <Box
                    style={{
                      marginTop: 20,
                      marginBottom: 10,
                      marginLeft: 10,
                      marginRight: 10,
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid black",
                      flexDirection: "column",
                      borderRadius: 10,
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        width: "100%",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Typography
                        fontSize={10}
                        fontWeight={"bold"}
                        textAlign={"right"}
                        style={{
                          borderRight: "1px solid black",
                          paddingRight: 10,
                          marginLeft: 10,
                          width: 80,
                        }}
                      >{`Amount\n${symbol.toUpperCase()}`}</Typography>
                      <CustomInput
                        style={{ border: "none" }}
                        // value={totalValue}
                        type="number"
                        onChange={onChageValue}
                      />
                    </Box>
                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 0",
                      }}
                    >
                      <Typography
                        style={{
                          borderRight: "1px solid black",
                          width: "45%",
                          paddingLeft: 10,
                        }}
                      >
                        Rs 0
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "55%",
                          padding: "0 10px",
                        }}
                      >
                        <div>25%</div>
                        <div>50%</div>
                        <div>75%</div>
                        <div>100%</div>
                      </Box>
                    </Box>
                  </Box>
                }
                <Box
                  style={{
                    marginTop: 20,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid black",
                    borderRadius: 10,
                  }}
                >
                  <Typography
                    fontSize={10}
                    fontWeight={"bold"}
                    textAlign={"right"}
                    style={{
                      borderRight: "1px solid black",
                      paddingRight: 10,
                      marginLeft: 10,
                      width: 80,
                    }}
                  >{`Total`}{" "}{"INR"}</Typography>
                  <CustomInput
                    style={{ border: "none" }}
                    value={totalValue}
                    type="number"
                    disabled
                    onChange={onChageValue}
                  />
                </Box>
                <Box py={"10px"}>
                  <Button
                    variant="contained"
                    style={{ width: "100%", backgroundColor: "red" }}
                  >{`SELL ${symbol.toUpperCase()}`}</Button>
                </Box>
              </Box>
            </TabPanel>
          </TabsUnstyled>
        </Box>
      </Modal>
    </Box>
  );
}

export default PopUpModal;
