import React,{useState} from "react";
import "./index.css";
import Footer from "../Footer";
import { display } from "@mui/system";
import Hamburger from 'hamburger-react'


const Card = ({ cardTitle, cardContent, cardImg }) => {
    return (
        <div className="card">
            <div className="card_container">
                <div>
                    <img className={"cardImg"} src={cardImg} alt={"img"} />
                </div>
                <p className="card_title">{cardTitle}</p>
                <p className="card_content">{cardContent}</p>
            </div>
        </div>
    );
};

function Landing() {
    const [isOpen, setOpen] = useState(false)


    return (
        <div className="container-div">
            <link rel="stylesheet" href="/lightMode.css" />
            <div className="header container">
                {/* <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
                    <symbol id="bootstrap" viewBox="0 0 118 94">
                        <title>Bootstrap</title>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.509 0c-6.733 0-11.715 5.893-11.492 12.284.214 6.14-.064 14.092-2.066 20.577C8.943 39.365 5.547 43.485 0 44.014v5.972c5.547.529 8.943 4.649 10.951 11.153 2.002 6.485 2.28 14.437 2.066 20.577C12.794 88.106 17.776 94 24.51 94H93.5c6.733 0 11.714-5.893 11.491-12.284-.214-6.14.064-14.092 2.066-20.577 2.009-6.504 5.396-10.624 10.943-11.153v-5.972c-5.547-.529-8.934-4.649-10.943-11.153-2.002-6.484-2.28-14.437-2.066-20.577C105.214 5.894 100.233 0 93.5 0H24.508zM80 57.863C80 66.663 73.436 72 62.543 72H44a2 2 0 01-2-2V24a2 2 0 012-2h18.437c9.083 0 15.044 4.92 15.044 12.474 0 5.302-4.01 10.049-9.119 10.88v.277C75.317 46.394 80 51.21 80 57.863zM60.521 28.34H49.948v14.934h8.905c6.884 0 10.68-2.772 10.68-7.727 0-4.643-3.264-7.207-9.012-7.207zM49.948 49.2v16.458H60.91c7.167 0 10.964-2.876 10.964-8.281 0-5.406-3.903-8.178-11.425-8.178H49.948z"></path>
                    </symbol>
                    <symbol id="home" viewBox="0 0 16 16">
                        <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z"></path>
                    </symbol>
                    <symbol id="speedometer2" viewBox="0 0 16 16">
                        <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z"></path>
                        <path fill-rule="evenodd" d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"></path>
                    </symbol>
                    <symbol id="table" viewBox="0 0 16 16">
                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"></path>
                    </symbol>
                    <symbol id="people-circle" viewBox="0 0 16 16">
                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"></path>
                        <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"></path>
                    </symbol>
                    <symbol id="grid" viewBox="0 0 16 16">
                        <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"></path>
                    </symbol>
                </svg> */}


                <div style={{position:"relative"}} class="">
                    <header class="d-flex flex-wrap bg-black text-light align-items-center justify-content-center justify-content-md-between py-3 mb-4">
                        <a href="/" class="header_brand d-flex align-items-center col-md-2 mb-2 mb-md-0 text-light text-decoration-none">
                            PPR
                        </a>
        <Hamburger className="mobileviewcheck" size={20} toggled={isOpen} toggle={setOpen} />
                        <ul style={{position:"relative",right:"35%"}} class="desktop_view nav col-12 text-light sub-title col-md-auto mb-2  mb-md-0">
                            <li style={{opacity:"0.8", fontWeight:"bold"}} ><a class="nav-link px-3 link-light">Squaꜩ</a></li>
                            <li  style={{ fontWeight:"bold"}}><a href="https://zenith.payperfi.com" target="_blank" class="nav-link px-3 link-light">Zenith</a></li>
                        </ul>
                        <ul style={{position:"relative",right:"35%"}} class={`${isOpen?"menuactive_true":""} menuactive`}>
                            <li style={{opacity:"0.8", fontWeight:"bold",listStyle:"none"}} ><a class="nav-link px-3 link-light">Squaꜩ</a></li>
                            <li  style={{ fontWeight:"bold",listStyle:"none"}}><a href="#" class="nav-link px-3 link-light">Zenith</a></li>
                        </ul>
                    </header>
                <span className="Homerightsphere glow"> </span>
                </div>
            </div>
            <div className="content container">
                <div className="main_content">
              
                    <div className="left_content">
                        <p className="title"><span className="payper">PAYPER</span><br /><span className="finance">FINANCE</span></p>
                        <div className="sub_title">
                            <p>
                            Innovating DeFi on ꜩ
                            </p>
                        </div>
                        <div style={{
                            background:"linear-gradient(90deg, #0057FF -36.21%, #00A3FF 25.8%, #32FFDA 89.29%, #07FFFF 137.78%)",
                            color:"#000000",
                            border:"none"
                        }} className="btn custom_btn">
                            <a href="https://zenith.payperfi.com" target="_blank" style={{textDecoration:"None", color:"white"}}><p style={{fontWeight:"bold", fontSize:"16px"}}>View Zenith</p></a>
                        </div>
                    </div>
                    <div className="homwgif">
                        </div>
                    <div className="right_content">
                        <img src="/logo_full.png" alt="Payper logo" />
                        <div class="outer">
                            <div class="item">
                                <img src="./icons/landing_coin.png" class="seed"></img>
                            </div>
                        </div>
                 
                    </div>
                   
                </div>
                <div className="about_ecosystem sections">
                    <div className="join_eco">
                        <p>Join The Ecosystem</p>
                    </div>
                    <div className="chip_container ">
                        <ul>
                            <li className="">
                                <div className="token">
                                    <img src="./icons/ppr.png" alt="ppr" />
                                    <p className="col-9 text-end">PPR Token</p>
                                </div>
                            </li>
                            <li className="">
                                <div className="token">
                                    <img src="./icons/opt.png" alt="ppr" />
                                    <p className="col-9 text-end">Learn Opt</p>
                                </div>
                            </li>
                            <li className="">
                                <div className="token align-items-end">
                                    <img src="./icons/staking.png" alt="ppr" />
                                    <p className="col-9 text-end">Staking</p>
                                </div>
                            </li>
                            <li className="">
                                <div className="token">
                                    <img src="./icons/airdrop.png" alt="ppr" />
                                    <p className="col-9 text-end">Airdrop</p>
                                </div>
                            </li>
                            <li className="">
                                <div className="token">
                                    <img src="./icons/giveaway.png" alt="ppr" />
                                    <p className="col-9 text-end">Giveaway</p>
                                </div>
                            </li>
                            <li className="">
                                <div className="token">
                                    <img src="./icons/star.png" alt="ppr" />
                                    <p className="col-9 text-end">Play & Earn</p>
                                </div>
                            </li>
                        </ul>
                        <ul>
                            <li className="">
                                <div className="token">
                                    <img src="./icons/expert.png" alt="ppr" />
                                    <p className="col-9 text-end">Expert Advice</p>
                                </div>
                            </li>
                            <li className="">
                                <div className="token">
                                    <img src="./icons/reports.png" alt="ppr" />
                                    <p className="col-9 text-end">Reports</p>
                                </div>
                            </li><li className="">
                                <div className="token">
                                    <img src="./icons/gamify.png" alt="ppr" />
                                    <p className="col-9 text-end">Gamify Trading</p>
                                </div>
                            </li><li className="">
                                <div className="token">
                                    <img src="./icons/nft.png" alt="ppr" />
                                    <p className="col-9 text-end">NFT's</p>
                                </div>
                            </li><li className="">
                                <div className="token">
                                    <img src="./icons/leaderboard.png" alt="ppr" />
                                    <p className="col-9 text-end">Leaderboard</p>
                                </div>
                            </li>
                            <li className="">
                                <div className="token">
                                    <img src="./icons/community.png" alt="ppr" />
                                    <p className="col-9 text-end">Community</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="learn_more sections ">
                    <div className="content">
                        <h1 style={{fontWeight:"1000"}}>Learn More</h1>
                        <p className="sub_title">
                        Payper Finance is the best way to enter into DeFi on ꜩ.
                        </p>
                        <p className="sub_title">
                        Payper finance provides users a bunch of DeFi protocol over ꜩ such as derivatives trading (Futures and Options).
                        </p>
                        <a href="https://zenith.payperfi.com" target="_blank"><button className="btn custom_btn mt-3">learn More</button></a>
                    </div>
                    <div className="x"></div>
                    <div
                        className="options"
                        style={{
                            backgroundImage: `url("./back.gif")`,
                            backgroundPosition: "center",
                            backdropFilter: "opactity(0.5)",
                        }}
                    >
                        {/* <img src='/back.gif' className='back_gif' /> */}
                        <div className="option_images">
                            <div className="option_enclose"><img src="/fut.png" /></div>
                            <div className="divider">
                                <div className="option_enclose"><img src="/opt.png" /></div>
                                <div className="option_enclose"><img src="/alt.png" /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="why_choose sections">
                    <div className="title">
                        <p>Why Choose Payper Finance?</p>
                        <div className="cards">
                            <Card
                                cardContent={
                                    "Most easy to understand platform which provide better user experience"
                                }
                                cardTitle={"Easy UI"}
                                cardImg={"/ui.png"}
                            />
                            <Card
                                cardContent={
                                    "Build on Tezos platform,benefiting in fractional fees for transaction"
                                }
                                cardTitle={"LOW FEES"}
                                cardImg={"/wallet.png"}
                            />
                            <Card
                                cardContent={
                                    "We provide our tokens as reward for trading,which you can use or cash out"
                                }
                                cardTitle={"REWARDS"}
                                cardImg={"/rewards.png"}
                            />
                        </div>
                    </div>
                </div>
                <div className="join_discord sections">
                    <ul class="circles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                    <div className="title">
                        <p>Looking to Get started on payper finance?</p>
                    </div>
                    <div className="join_discord_button custom_btn">
                    <a href="https://zenith.payperfi.com" target="_blank" style={{cursor: "pointer", textDecoration: "None", color:"lightGreen"}}><p>Join Discord</p></a>
                    </div>
                </div>
                <div className=" payperfproduct">
                <div className="upcoming">
                    <div className="upcoming_image">
                        <img src="img/LogoHome.png"  style={{width:"220px"}}/>
                        <span style={{fontSize:"44px",marginLeft:"30px",color:"#9c59f4",fontWeight:"bold"}}>Zenith</span>
                    </div>
                    <div className="upcoming_content">
                <p className="title">Zenith</p>

                        <p className="sub_title w-100 mb-3">Zenith is perpetual future and options exchange that provides users to go long or short on perpetual of your favorite assets with up to 10x leverage in all market conditions. </p>
                        <a href="https://zenith.payperfi.com" target="_blank"><button style={{
                            background:"linear-gradient(90deg, #0057FF -36.21%, #00A3FF 25.8%, #32FFDA 89.29%, #07FFFF 137.78%)",
                            color:"#ffff",
                            fontWeight:"bold",
                            border:"none"
                        }} className="btn custom_btn">Get Whitelisted</button></a>
                    </div>
                </div>
                </div>
                <div className=" payperfproduct">
                <div className="uses">
                <div style={{textAlign:"left"}} className="uses_content">
                <p className="title">Squaꜩ</p>
                        <p className="sub_title w-100 mb-3">Long SquaTz (XTZ²) gives you a leveraged position with unlimited upside, protected downside, and no liquidations. Compared to a 2x leveraged position, you make more when XTZ goes up and lose less when XTZ goes down (excluding funding). Eg. If XTZ goes up 5x, SquaTz goes up 25x. </p>
                        <button style={{
                            background:"linear-gradient(90deg, #0057FF -36.21%, #00A3FF 25.8%, #32FFDA 89.29%, #07FFFF 137.78%)",
                            color:"#ffff",
                            fontWeight:"bold",
                            border:"none"
                        }} className="btn custom_btn">Coming Soon</button>
                    </div>
                    <div className="uses_image">
                        <img src="/squatz.png" style={{width:"95%"}}/>
                    </div>
                </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Landing;
