import React from 'react'
import { Button } from '@mui/material'
import './footercss.css'
export default function Footer() {
    return (
        <section className="Landing_join scrollFade">
            <div>
          <span className="landingdetails_sphere1 glow"></span>

                <img  src='img/footerimg.png'/>
                <h2>Join us here for latest updates</h2>
                <h4>To keep up to date, join our Discord server</h4>
                <form action="https://discord.gg/DMVS3mWpaN" target="_blank">
                    <Button type="submit">JOIN DISCORD</Button>
                </form>
            </div>
            <footer>
                <div>
                    <img style={{ width: "100px", height: "100px" }} src="/logo_full.png" />
                    <h2><span>Payper</span>
                        Finance </h2>
                </div>
                <div>
                    <ul>
                        <li>
                            <a href="https://discord.gg/DMVS3mWpaN" target="_blank">
                                <img src="img/discord.png" />
                            </a>
                        </li>

                        <li>
                            <a href="https://twitter.com/PayperFinance" target="_blank">
                                <img src="img/pngegg.png" />
                            </a>
                        </li>
                        <li>
                            <a href=" https://t.me/Payper_Finance" target="_blank">
                                <img src="img/pngegg (1).png" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/payper-finance/" target="_blank">
                                <img src="img/linkdn.png" />
                            </a>
                        </li>
                    </ul>
                </div>
            </footer>
        </section>
    )
}
