import logo from "./logo.svg";
import "./App.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { COINS_URL } from "./consts";
import PopUpModal from "./components/Modal";
import { Button, Tab, Tabs, Box, Typography, Table, TableHead, TableCell, TableContainer, Paper, TableRow } from "@mui/material";

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function App() {
  const [hover, setHover] = React.useState("");
  const [data, setData] = React.useState([]);
  const [chartId, setChartId] = React.useState("");
  const [buy, setBuy] = useState(false);
  const [showPopUp, setShowPopup] = useState(false);
  const [symbol, setSymbol] = useState("");
  const [coinValue, setCoinValue] = useState("");
  const [totalValue, setTotalValue] = useState(0);
  const [open, SetOpen] = useState(true)
  const [currentTab, setCurrentTab] = useState(0);

  const onChageValue = (e) => {
    const count = e.target.value;
    if (count) {
      setTotalValue(coinValue * count);
    }
  };

  const getCoinData = async () => {
    const req = await axios.get(COINS_URL);
    if (req.status !== 200) {
      alert("There is some error.!");
    } else {
      setData(req.data);
    }
  };

  useEffect(() => {
    setInterval(() => {
      getCoinData();
    }, 5000);
  }, []);

  const showBuy = (symbol, value) => {
    setBuy(true);
    setShowPopup(true);
    setSymbol(symbol);
    setCoinValue(value);
  };
  const showSell = (symbol, value) => {
    setBuy(false);
    setShowPopup(true);
    setSymbol(symbol);
    setCoinValue(value);
  };
  React.useEffect(() => {
    if (!chartId) return;
    const options = {
      width: "100%",
      height: "60%",
      symbol: chartId,
      interval: "D",
      timezone: "Etc/UTC",
      theme: "light",
      style: "1",
      locale: "in",
      toolbar_bg: "#f1f3f6",
      enable_publishing: false,
      allow_symbol_change: true,
      container_id: "tradingview_95ada",
    };
    console.log(options);
    const { TradingView } = window;
    if (typeof TradingView !== undefined) {
      new TradingView.widget(options);
    }
  }, [chartId]);
  return (
    <div style={{ width: "100vw", display: "flex", height: "100vh", backgroundColor: "black" }}>
      {/* <div style={{ height: 70, position: "absolute", left: 0, zIndex: 10000, backgroundColor: "white", boxShadow: "0 0 14px 1px rgba(0,0,0,01)", width: "100%" }}>      </div> */}
      <div
        style={{
          width: "30%",
          paddingTop: 10,
          borderRight: "1px solid black",
          marginRight: 20,
          paddingLeft: 10,
          overflow: "scroll",
          // marginTop: 70,
          display: !open ? "none" : ""
          // transform: !open ? "translateX(-100%)" : ""
        }}
      >
        {data && data.length > 0
          ? data.map((coin, index) => {
            return (
              <div
                onMouseOver={() => setHover(coin.id)}
                onMouseLeave={() => setHover("")}
                key={coin.id}
                style={{
                  display: "flex",
                  position: "relative",
                  paddingBottom: 10,
                  overflow: "scroll",
                }}
              >
                <img
                  src={coin.image}
                  alt={coin.id}
                  style={{ width: 50, height: 50, marginRight: 10 }}
                />
                <p
                  style={{
                    width: "70%",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {coin.id}
                </p>
                <p
                  style={{
                    marginLeft: 10,
                    paddingRight: 10,
                    display: "inline-block",
                    fontWeight: "bold",
                  }}
                >{`₹${coin.current_price}`}</p>

                <div
                  style={{
                    display: hover === coin.id ? "flex" : "none",
                    alignItems: "center",
                    justifyContent: "space-between",
                    position: "absolute",
                    backgroundColor: "white",
                    right: 10,
                    minWidth: 150,
                    padding: "0 12px",
                    borderRadius: 12,
                    height: "100%",
                    boxShadow: "3px 4px 13px -7px rgba(163,151,163,0.48)",
                  }}
                >
                  <button
                    onClick={() => showBuy(coin.symbol, coin.current_price)}
                  >
                    B
                  </button>
                  <button
                    onClick={() => showSell(coin.symbol, coin.current_price)}
                  >
                    S
                  </button>
                  <button onClick={() => setChartId(coin.symbol)}>C</button>
                </div>
              </div>
            );
          })
          : "No Coins"}
      </div>
      <div style={{ width: !open ? '90%' : '66%', height: '90%', margin: 'auto' }}>
        <Button onClick={getCoinData}>Refresh</Button>
        <Button onClick={() => SetOpen(!open)}>{open ? "Close" : "Open"}</Button>
        <div
          className="tradingview-widget-container"
          style={{ width: "100%", height: "50%" }}
        >
          {chartId ?
            <>
              <div id="tradingview_95ada"></div>
              <div className="tradingview-widget-copyright">By TradingView</div>
            </>
            : <p style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Chart Appears here</p>}
        </div>
        <div style={{ marginTop: 40, borderTop: '1px solid black' }}>
          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexDirection={"row"}>
            <Tabs defalutValue={currentTab} onChange={(e, n) => setCurrentTab(n)}>
              <Tab label={"completed"} color="red">Completed</Tab>
              <Tab label={"pending"}>Pending</Tab>
            </Tabs>
            <div>
              <p>Total P&L $1000</p>
            </div>
          </Box>
          <Box>
            <TabPanel value={currentTab} index={0}>
              <TableContainer component={Paper} style={{ padding: 0 }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Order Date</TableCell>
                      <TableCell>Coin</TableCell>
                      <TableCell>Order Type</TableCell>
                      <TableCell>Qunitity</TableCell>
                      <TableCell>Entry Price</TableCell>
                      <TableCell>Fee</TableCell>
                      <TableCell>Profit/Loss</TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <TableContainer style={{ padding: 0 }}>
                <Table aria-label="simple table" style={{ padding: 0 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Order Date</TableCell>
                      <TableCell>Coin</TableCell>
                      <TableCell>Order Type</TableCell>
                      <TableCell>Qunitity</TableCell>
                      <TableCell>Entry Price</TableCell>
                      <TableCell>Current Price</TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </TabPanel>
          </Box>
        </div>
      </div>
      <PopUpModal
        open={showPopUp}
        onClose={() => setShowPopup(false)}
        symbol={symbol}
        buy={buy}
        value={coinValue}
        onChageValue={onChageValue}
        totalValue={totalValue}
      />
    </div>
  );
}

export default App;
