import React from 'react'

import Landing from './components/Landing'

function NewApp() {
    return (
        <>
            <Landing />
        </>
    )
}

export default NewApp